@import "../../App.scss";

.notice-board-holder {
    width: 90%;
    margin-left: 20px;
    h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #484750;
    }

    .users-header {
        background: #fff;
        padding: 25px 16px;
        margin-top: 8px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 56px;
        min-height: 131px;

        button {
            border: none;
            border-radius: 4px;
            background: $button-color;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            width: 200px;
            height: 40px;
            img {
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin-right: 15px;
            }
        }
        .has-search {
            position: relative;
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                padding-left: 1.9rem;
            }
            #search {
                background-color: #f7f7fa;
                border: none;
                &::-webkit-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::-moz-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &:-ms-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
            }
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        select {
            background-color: #f2f2f2;
            border: none;
            background-image: url("../../assets/select-indicator.svg");
            background-position: 85% center;
            &:focus {
                box-shadow: none !important;
                border: none;
            }
        }
    }
    .table-admin {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        background: #fff;
        margin-top: -60px;
        .table {
            margin-bottom: 0px;
            thead {
                hr {
                    margin-bottom: -1rem;
                    border-top: none;
                    margin-right: 0px;
                    margin-top: 0px;
                }
                tr {
                    th {
                        // box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.25);
                        border-bottom: 2px solid $chart-color;
                        border-top: none;
                        color: $chart-color;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 100%;
                        // position: absolute;
                        margin-left: 0px;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        vertical-align: middle;
                        border-top: 8px solid #f6f6f6 !important;
                        text-align: center;
                        .user-table-holder {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .user-icon-table {
                                width: 30px;
                                height: 30px;
                                overflow: hidden;
                                border-radius: 50%;
                                display: flex;
                                color: #fff;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        p {
                            color: $button-color;
                        }
                        .deco {
                            text-decoration: underline !important;
                        }
                        .deco-none {
                            text-decoration: none !important;
                        }
                    }
                    .action {
                        cursor: pointer;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        button {
                            border: none;
                            background: none;
                            //    &:hover{
                            //        background: #DDDDE2;
                            //        width: 30px;
                            //        height: 30px;
                            //        border-radius: 50%;

                            //    }
                        }
                    }
                }
            }
        }
    }
    .showing-results {
        padding-top: 8px;
        position: absolute;
        .clear {
            display: flex;

            .select-list {
                ul {
                    margin-top: 7px;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: "Roboto", sans-serif;
                        font-size: 12px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 5px 7px;
                        background-color: #ffffff;
                        margin-right: 5px;
                        border-radius: 50px;
                        margin-bottom: 10px;
                        margin-top: -2px;
                        img {
                            width: 8px;
                            cursor: pointer;
                            vertical-align: middle;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            span {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
                cursor: pointer;
            }
        }
    }
    .user-pagination {
        display: flex;
        align-items: center;
        margin-top: 25px;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            width: 110px;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            input {
                width: 30%;
                border-radius: 4px;
                border: 1px solid #9b9aa3;
                padding: 0.25rem 0.3rem;
                text-align: center;
            }
        }
    }
    .add-notice {
        background-color: #fff;
        padding: 20px 35px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        width: 98%;
        small {
            font-size: 14px;
            color: red;
        }
        .col-sm-12.email-id-row {
            border: 1px solid #ccc;
            height: 38px;
            // overflow: auto;
            width: 90%;
            // max-height: 35px;
            // overflow-y: auto;
            // overflow-x: hidden;
            cursor: pointer;
            border: 1px solid $chart-color;
        }
        .col-sm-12.email-id-row input {
            border: none;
            outline: 0px;
            // padding-top: 3px;
            width: 32%;
            height: 22px;
            margin-top: -1px;
            margin-left: 5px;
            padding: 4px;
        }
        .all-mail {
            display: flex;
            // flex-wrap: wrap;
            margin-left: -5px;
            .MuiChip-sizeSmall {
                background: #fff 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                margin-top: -5px;
            }
            .MuiChip-label {
                max-width: 155px;
            }
            .more {
                position: absolute;
                right: 2px;
                top: 8px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
            }
        }
        .email-suggestion {
            position: absolute;
            z-index: 2;
            width: 56%;
            // max-width: 90%;
            // left:2px;
            background: #fff;
            box-shadow: 0px 5px 7px 2px #0000001f;
            text-align: left;
            margin-top: 0px;
            // border-bottom-left-radius: 5px;
            border-radius: 4px;
            max-height: 300px;
            overflow: auto;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            ul {
                &:hover li:hover {
                    background: #ddd;
                }
                li {
                    display: block;
                    cursor: pointer;
                    width: 100%;
                    padding: 7px 0 6px 20px;
                    border-bottom: 1px solid #00000026;
                    margin: 0;
                    font-size: 14px;
                    height: 50px;
                    color: #696873;
                    // padding-left: 11px;
                    // .intial {
                    //   border-radius: 50px;
                    //   font-size: 17px;
                    //   // width: 25px;
                    //   height: 50px;
                    //   // border: 1px solid #6457e8;
                    //   display: flex;
                    //   justify-content: center;
                    //   align-items: center;
                    //   max-width: 37px;
                    // }
                }
            }
        }
        .notice-header {
            display: flex;
            justify-content: space-between;
            width: 58%;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
            p {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #484750;
            }
        }
        .select-all {
            cursor: pointer;
            border: none !important;
            box-shadow: none !important;
            background-image: url("../../assets/select-indicator1.svg");
            background-position: 100% center;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            cursor: pointer;
            option {
                color: #696873;
            }
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 90%;
            li {
                margin-right: 0px !important;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
                &:nth-child(2) {
                    margin-left: 40px;
                }
            }
        }
        textarea {
            resize: none;
            width: 90%;
            border-color: $chart-color;
            height: 115px;
        }
        input {
            width: 90%;
            border: 1px solid $chart-color;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: none;
            }
            &::-webkit-input-placeholder {
                color: #9b9aa3;
                font-size: 12px;
            }
            &::-moz-placeholder {
                color: #9b9aa3;
                font-size: 12px;
            }
            &:-ms-input-placeholder {
                color: #9b9aa3;
                font-size: 12px;
            }
            &::placeholder {
                color: #9b9aa3;
                font-size: 12px;
            }
        }
        input[type="date"] {
            width: 25%;
            position: relative;
        }
        ul {
            list-style: none;
            margin-top: 2px;
            li {
                display: inline-block;
                margin-right: 70px;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
                cursor: pointer;
                p {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    cursor: pointer;
                }
                .file-upload {
                    position: absolute;
                    left: -85px;
                    /* right: 106px; */
                    height: 30px;
                    width: 15vw;
                    opacity: 0;
                    top: -3px;
                    cursor: pointer;
                }
            }
        }
        .resource-holder {
            list-style: none;
            margin-top: 10px;
            width: 90%;
            .resource {
                display: flex;
                min-height: 35px;
                width: 100%;
                padding: 8px 4px;
                position: relative;
                .edit-bin {
                    cursor: pointer;
                }

                .recent-title {
                    // margin-left: -30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    /* align-items: baseline; */
                    // height: 80px;
                    h1 {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        font-family: Roboto;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                }
            }
        }
    }
}

.select-modal {
    width: 47%;
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .notice-header {
        // display: flex;
        // justify-content: space-between;
        // width: 60%;
        text-align: center;
        // padding: 5px 10px;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        h3 {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
            }
        }
    }
    .group-button {
        text-align: center;
        button {
            margin-top: 10px;
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            // border: none;
            padding: 7px;
            width: 120px;
            border-radius: 4px;
        }
        .active-btn {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            width: 120px;
            font-weight: bold;
        }
    }
    .select-buttons {
        // text-align: center;
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 15px;
        .subject-msg {
            background: #f7f7fa;
            margin-top: 10px;
            width: 97.5%;
            border: 1px solid #dddde2;
            box-sizing: border-box;
            border-radius: 4px;
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
                padding: 15px;
            }
        }
        .select-program {
            width: 50%;
            .css-2b097c-container {
                .css-1okebmr-indicatorSeparator {
                    display: none;
                }
            }
            .css-yk16xz-control {
                background-color: #f7f7fa;
                border: 1px solid #dddde2;
                &:focus {
                    background-color: #f7f7fa;
                    border: 1px solid #dddde2;
                }
            }
            .css-1pahdxg-control {
                background-color: #f7f7fa !important;
                border: 1px solid #dddde2 !important;
                box-shadow: none !important;
                :hover {
                    border: #dddde2 !important;
                }
            }
            margin-right: 10px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            text-align: left;
        }
    }
    .done-btn {
        padding-left: 80px;
        padding-right: 80px;
        button {
            border: none;
            background-color: $button-color;
            width: 136px;
            height: 32px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            border-radius: 2px;
            &:nth-child(1) {
                border: 1px solid #f8f8f8;
                background-color: #ffffff;
                color: #222222;
            }
        }
    }
    .select-student {
        background-color: #f7f7fa;
        margin-left: 80px;
        margin-right: 89px;
        border: 1px solid #dddde2;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 5px;
        input[type="checkbox"] {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0;
            background: #ffffff;
            height: 16px;
            width: 16px;
            border: 1px solid #9b9aa3;
            border-radius: 4px;
            &:checked {
                background: #00b24b;
            }
            &:after {
                content: "";
                position: relative;
                left: 35%;
                top: 15%;
                width: 35%;
                height: 60%;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                display: none;
            }
            &:checked:after {
                display: block;
            }
        }
        .row-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: rgba(204, 204, 204, 0.48) solid 1px;

            p {
                font-family: Roboto;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                letter-spacing: 0px;
                padding-bottom: 5px;
            }
            .student-tab {
                // border-top: rgba(204, 204, 204, 0.48) solid 1px;
                margin-bottom: 0;
                // background-color: #fff;
                overflow: visible;
                left: 0;
                // float: right;

                ul {
                    overflow: auto;
                    white-space: nowrap;
                    padding-bottom: 0px;
                    list-style-type: none;

                    li {
                        display: inline-block;
                        margin-right: 10px;
                        cursor: pointer;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        padding-bottom: 5px;
                        font-family: Roboto;
                    }
                }
                .active-tab {
                    border-bottom: 2px solid $button-color;
                }
            }
        }
        .student-list {
            list-style: none;
            overflow: auto;
            height: 200px;
            scrollbar-width: thin;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #c4c4c4;
            }

            li {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                display: flex;
                padding: 5px 0px;
                justify-content: flex-start;
                align-items: center;
            }
            width: 100%;

            .accordion .card-header:after {
                content: url("../../assets/arrow-up.svg");
                width: 15px;
                margin-right: 15px;
                float: left;
                margin-top: -19px;
                cursor: pointer;
                // background-image: url('../../images/arrow-down.svg');
            }
            .accordion .card-header.collapsed:after {
                /* symbol for "collapsed" panels */
                content: url("../../assets/arrow-down.svg");
                width: 15px;
                margin-right: 15px;
                margin-top: -19px;
                cursor: pointer;
            }
            .accordion {
                .card {
                    border: none;
                    border-bottom: 5px solid #f6f6f6 !important;
                    &:not(:last-of-type) {
                        border-bottom: none;
                    }
                }
                .card-header {
                    padding: 5px;
                    border-bottom: 1px solid #fff;
                    .card-title {
                        margin-bottom: 0px;
                        p {
                            font: normal normal 600 14px/18px Roboto;
                            color: #222222;
                            span {
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 18px;
                            }
                        }
                    }
                }
                .card-body {
                    padding: 0rem;
                    background-color: #f7f7fa;
                }
            }
            .content-holder {
                list-style: none;
                .resource {
                    // height: 140px;
                    display: flex;
                    min-height: 40px;
                    width: 100%;
                    padding: 4px;
                    position: relative;
                    overflow-x: hidden;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 33px;
                    border-bottom: 1px solid #fff;
                    .resource-image {
                        margin-left: 15px;
                    }
                    .recent {
                        width: 65px;
                        height: 65px;
                    }
                    .recent-title {
                        margin-left: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        cursor: default;
                        /* align-items: baseline; */
                        h1 {
                            text-align: left;
                            font: normal normal 600 14px/18px Roboto;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// for student notice board

.student-notice-holder {
    // margin-left: 5px;
    // position: relative;
    .animated-search {
        position: absolute;
        right: 0;
        overflow: hidden;
        top: -12px;
        #express-form-typeahead {
            position: relative;
            border: none !important;
            border-bottom: 1px solid #dddde2 !important;
            background-color: unset;
            box-sizing: border-box;
            outline: none;
            padding: 0 0 0 40px;
            transition: width 0.8s ease;
            z-index: 2;
            opacity: 0;
            top: -10px;
            cursor: pointer;
            &:focus {
                z-index: 0;
                opacity: 1;
            }
        }
        .symbol {
            position: absolute;
            top: 0;
            left: 0px;
            z-index: 1;
            img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
        }
        #express-form-typeahead {
            border: none;
            cursor: pointer;
            height: 37px;
            margin: 3px 0;
            padding: 0 0 0 34px;
            position: relative;
            -webkit-transition:
                width 400ms ease,
                background 400ms ease;
            transition:
                width 400ms ease,
                background 400ms ease;
            width: 0;
            &:focus {
                // background-color: #fff;
                cursor: text;
                outline: 0;
                width: 280px;
                left: -10px;
                margin: 3px 0;
                padding: 0 0 0 34px;
                transition: width 0.8s ease;
                z-index: 1;
            }
        }
        .close-input {
            position: absolute;
            right: 0px;
            right: 18px;
            top: 3px;
            z-index: 1;
            cursor: pointer;
        }
    }
    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #222222;
        margin-left: 5px;
    }
    ul {
        list-style: none;
        width: 93%;
        display: flex;
        align-self: stretch;
        flex-wrap: wrap;
        display: -ms-flexbox;
        display: -webkit-flex;
        -webkit-align-items: inherit;
        -ms-flex-align: inherit;
        align-items: inherit;
        flex-wrap: wrap;
        li {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 6px;
            padding: 10px 15px;
            background-color: #fff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            width: 32%;
            cursor: pointer;
            .new-notification {
                height: 20px;
                .new {
                    width: 24px;
                    height: 16px;
                    background-color: #ff5a5f;
                    border-radius: 4px;
                    font-weight: bold;
                    font-size: 8px;
                    line-height: 10px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                    margin-top: -2px;
                }
            }
            h2 {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                margin-top: 7px;
                // height: 33px;
            }
            p {
                margin-top: 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .flex-item {
                -webkit-flex: 0 1 auto;
                -ms-flex: 0 1 auto;
                flex: 0 1 auto;
                small {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
            }
        }
    }
}

.notification-modal {
    width: 730px;
    .sticky-close {
        position: absolute;
        right: 20px;
        top: 25px;
        cursor: pointer;
    }
    .modal-body1 {
        .sticky-holder {
            position: absolute;
            top: -32px;
            right: 0px;
            width: 30px;
            height: 30px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }

        table {
            width: 100%;
            tbody {
                display: block;
                overflow: auto;
                max-height: 400px;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
            }
            thead,
            tbody tr {
                display: table;
                width: 100%;
                table-layout: fixed; /* even columns width , fix width of table too*/
            }
            thead {
                width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
            }
        }
    }
    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #222222;
        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
            margin-top: 3px;
        }
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
    .resource-holder {
        list-style: none;
        margin-top: 20px;
        li {
            margin: 5px 0px;

            .recent-title {
                cursor: pointer;
                h1 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                }
            }
        }
    }
}
.elipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.add-content-modal {
    width: 50%;
    .modal-header {
        position: relative;
        h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
            padding-left: 14px;
        }
        .sticky-close {
            position: absolute;
            right: 38px;
            top: 18px;
            cursor: pointer;
        }
    }
    .content-drawer {
        padding: 0px;
        // overflow: auto;
        // // max-height: 400px;
        // &::-webkit-scrollbar {
        //     display: none;
        // }
        #scrollableDiv {
            & ::-webkit-scrollbar-track {
                border-radius: 0px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 8px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                max-height: 0px;
                background-color: #e9e8e8;
            }
            scrollbar-width: thin;
        }

        .content-list {
            list-style: none;
            // padding: 22px 30px;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0px;
                border-bottom: 1px solid #dddde2;
                padding-left: 28px;
                padding-right: 30px;
                .subject-name {
                    article {
                        img {
                            width: 70px;
                            height: 40px;
                        }
                    }

                    h3 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                        img {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                        }
                    }
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                }
            }
        }
        .checkmark {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: block;
            stroke-width: 5;
            stroke: #fff;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px #7ac142;
            animation:
                fill 0.4s ease-in-out 0.4s forwards,
                scale 0.3s ease-in-out 0.9s both;
        }

        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 7;
            stroke-miterlimit: 10;
            stroke: #f5821f;
            fill: #fff;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }

        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            stroke: #f5821f;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }

        @keyframes stroke {
            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes scale {
            0%,
            100% {
                transform: none;
            }
            50% {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }
}

@media (max-width: 767px) {
    .notice-board-holder {
        display: none;
    }
    .add-content-modal {
        width: unset;
        .modal-header {
            h5 {
                padding-left: 3px;
            }
            .sticky-close {
                position: absolute;
                right: 20px;
                top: 25px;
                cursor: pointer;
            }
        }
        .content-drawer {
            max-height: 800px;
            .content-list {
                li {
                    padding-left: 15px;
                    padding-right: 10px;
                }
            }
        }
    }

    .student-notice-holder {
        margin-left: 0px;
        margin-bottom: 40px;
        .animated-search {
            position: absolute;
            right: 0;
            overflow: hidden;
            top: -5px;
            #express-form-typeahead {
                position: relative;
                border: none !important;
                border-bottom: 1px solid #dddde2 !important;
                background-color: unset;
                box-sizing: border-box;
                outline: none;
                padding: 0 0 0 40px;
                transition: width 0.8s ease;
                z-index: 2;
                opacity: 0;
                top: -10px;
                cursor: pointer;
                &:focus {
                    z-index: 0;
                    opacity: 1;
                }
            }
            .symbol {
                position: absolute;
                top: 0;
                left: 0px;
                z-index: 1;
                img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }
            #express-form-typeahead {
                border: none;
                cursor: pointer;
                height: 37px;
                margin: 3px 0;
                padding: 0 0 0 34px;
                position: relative;
                -webkit-transition:
                    width 400ms ease,
                    background 400ms ease;
                transition:
                    width 400ms ease,
                    background 400ms ease;
                width: 0;
                &:focus {
                    // background-color: #fff;
                    cursor: text;
                    outline: 0;
                    width: 200px;
                    right: -10px;
                    margin: 3px 0;
                    padding: 0 0 0 34px;
                    transition: width 0.8s ease;
                    z-index: 1;
                }
            }
            .close-input {
                position: absolute;
                right: 0px;
                right: 18px;
                top: 3px;
                z-index: 1;
                cursor: pointer;
            }
        }
        .empty-list {
            margin-top: 30px;
            img {
                width: 320px;
            }
        }
        ul {
            width: 96%;
            margin-top: 15px;
            li {
                width: 100%;
            }
        }
    }

    .notification-modal {
        width: unset;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .student-notice-holder ul li {
        width: 31%;
    }
}

.popover {
    .arrow {
        display: none;
    }
    .popover-details {
        padding: 20px;
    }
}
